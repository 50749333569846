import { useEffect } from "react";

export default function TheRealWorker({ searchParams }) {
  useEffect(() => {
    if (searchParams.afc) {
      localStorage.setItem("UberSlimAFC", searchParams.afc);
    }
  }, [searchParams]);

  return <></>;
}
